import React, { useState } from "react";
import Layout from "../../components/Layout";
import "./style.scss";

// Helper component to generate radio button groups
const RadioButtonGroup = ({
  name,
  label,
  options,
  value,
  onChange,
  required,
}) => {
  return (
    <div className="radio-group">
      <label className="radio-group-label">{label}*</label>
      <div className="radio-options">
        {options.map((option) => (
          <label key={option.value} className="radio-option">
            <input
              type="radio"
              name={name}
              value={option.value}
              checked={value === option.value}
              onChange={onChange}
              required={required}
            />
            {option.label}
          </label>
        ))}
      </div>
    </div>
  );
};

const SchoolTourFeedbackForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    overallImpression: "",
    recommend: "",
    facilitiesCondition: "",
    classrooms: "",
    outdoorFacilities: "",
    academicInfo: "",
    curriculumQuestions: "",
    educationConfidence: "",
    staffWarmth: "",
    staffResponsiveness: "",
    admissionsClarity: "",
    likeMost: "",
    likeLeast: "",
    suggestions: "",
    additionalComments: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting Form Data:", formData);

    let submitData = { ...formData };

    submitData["likeMost"] =
      formData.likeMost == "" ? "None" : formData.likeMost;
    submitData["likeLeast"] =
      formData.likeLeast == "" ? "None" : formData.likeLeast;
    submitData["suggestions"] =
      formData.suggestions == "" ? "None" : formData.suggestions;
    submitData["additionalComments"] =
      formData.additionalComments == "" ? "None" : formData.additionalComments;

    try {
      const response = await fetch(
        "https://chinmayavvdelhi.ac.in/api/public/school-feedback.php/submit",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(submitData),
        }
      );

      if (response.ok) {
        alert("Thank you for your feedback!");
        setFormData({
          firstName: "",
          lastName: "",
          overallImpression: "",
          recommend: "",
          facilitiesCondition: "",
          classrooms: "",
          outdoorFacilities: "",
          academicInfo: "",
          curriculumQuestions: "",
          educationConfidence: "",
          staffWarmth: "",
          staffResponsiveness: "",
          admissionsClarity: "",
          likeMost: "",
          likeLeast: "",
          suggestions: "",
          additionalComments: "",
        });
      } else {
        alert("Failed to submit feedback. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <Layout>
      <div className="parent-questionnaire">
        <h1 className="heading">School Tour Feedback Questionnaire</h1>
        <form onSubmit={handleSubmit}>
          <label>
            First Name*
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </label>

          <label>
            Last Name*
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </label>

          <h3>Section 1: Overall Impression</h3>

          <RadioButtonGroup
            name="overallImpression"
            label="What was your overall impression of our school?"
            options={[
              { value: "Excellent", label: "Excellent" },
              { value: "Good", label: "Good" },
              { value: "Fair", label: "Fair" },
              { value: "Poor", label: "Poor" },
            ]}
            value={formData.overallImpression}
            onChange={handleChange}
            required={true}
          />

          <RadioButtonGroup
            name="recommend"
            label="How likely are you to recommend our school to others?"
            options={[
              { value: "Very Likely", label: "Very Likely" },
              { value: "Likely", label: "Likely" },
              { value: "Neutral", label: "Neutral" },
              { value: "Unlikely", label: "Unlikely" },
              { value: "Very Unlikely", label: "Very Unlikely" },
            ]}
            value={formData.recommend}
            onChange={handleChange}
            required={true}
          />

          <h3>Section 2: Facilities and Infrastructure</h3>

          <RadioButtonGroup
            name="facilitiesCondition"
            label="How would you rate the condition and maintenance of our facilities?"
            options={[
              { value: "Excellent", label: "Excellent" },
              { value: "Good", label: "Good" },
              { value: "Fair", label: "Fair" },
              { value: "Poor", label: "Poor" },
            ]}
            value={formData.facilitiesCondition}
            onChange={handleChange}
            required={true}
          />

          <RadioButtonGroup
            name="classrooms"
            label="Were our classrooms and learning spaces well-equipped and organized?"
            options={[
              { value: "Excellent", label: "Excellent" },
              { value: "Good", label: "Good" },
              { value: "Fair", label: "Fair" },
              { value: "Poor", label: "Poor" },
            ]}
            value={formData.classrooms}
            onChange={handleChange}
            required={true}
          />

          <RadioButtonGroup
            name="outdoorFacilities"
            label="What do you think of our outdoor play areas and sports facilities?"
            options={[
              { value: "Excellent", label: "Excellent" },
              { value: "Good", label: "Good" },
              { value: "Fair", label: "Fair" },
              { value: "Poor", label: "Poor" },
            ]}
            value={formData.outdoorFacilities}
            onChange={handleChange}
            required={true}
          />

          <h3>Section 3: Academic Programs and Curriculum</h3>

          <RadioButtonGroup
            name="academicInfo"
            label="How would you rate the clarity and depth of information provided about our academic programs?"
            options={[
              { value: "Excellent", label: "Excellent" },
              { value: "Good", label: "Good" },
              { value: "Fair", label: "Fair" },
              { value: "Poor", label: "Poor" },
            ]}
            value={formData.academicInfo}
            onChange={handleChange}
            required={true}
          />

          <RadioButtonGroup
            name="curriculumQuestions"
            label="Did you receive satisfactory answers to your questions about the curriculum and teaching methods?"
            options={[
              { value: "Excellent", label: "Excellent" },
              { value: "Good", label: "Good" },
              { value: "Fair", label: "Fair" },
              { value: "Poor", label: "Poor" },
            ]}
            value={formData.curriculumQuestions}
            onChange={handleChange}
            required={true}
          />

          <RadioButtonGroup
            name="educationConfidence"
            label="How confident are you in our school's ability to provide a well-rounded education?"
            options={[
              { value: "Very Confident", label: "Very Confident" },
              { value: "Confident", label: "Confident" },
              { value: "Neutral", label: "Neutral" },
              { value: "Less Confident", label: "Less Confident" },
              { value: "Not Confident", label: "Not Confident" },
            ]}
            value={formData.educationConfidence}
            onChange={handleChange}
            required={true}
          />

          <h3>Section 4: Staff and Communication</h3>

          <RadioButtonGroup
            name="staffWarmth"
            label="How would you rate the warmth and welcoming nature of our staff?"
            options={[
              { value: "Excellent", label: "Excellent" },
              { value: "Good", label: "Good" },
              { value: "Fair", label: "Fair" },
              { value: "Poor", label: "Poor" },
            ]}
            value={formData.staffWarmth}
            onChange={handleChange}
            required={true}
          />

          <RadioButtonGroup
            name="staffResponsiveness"
            label="Were your questions answered promptly and effectively by our staff?"
            options={[
              { value: "Excellent", label: "Excellent" },
              { value: "Good", label: "Good" },
              { value: "Fair", label: "Fair" },
              { value: "Poor", label: "Poor" },
            ]}
            value={formData.staffResponsiveness}
            onChange={handleChange}
            required={true}
          />

          <RadioButtonGroup
            name="admissionsClarity"
            label="How clear were our admissions and enrollment processes explained?"
            options={[
              { value: "Excellent", label: "Excellent" },
              { value: "Good", label: "Good" },
              { value: "Fair", label: "Fair" },
              { value: "Poor", label: "Poor" },
            ]}
            value={formData.admissionsClarity}
            onChange={handleChange}
            required={true}
          />

          <h3>Section 5: Additional Comments</h3>

          <label>
            What did you like the most about our school?*
            <textarea
              name="likeMost"
              value={formData.likeMost}
              onChange={handleChange}
              required
            />
          </label>

          <label>
            What did you like the least about our school?*
            <textarea
              name="likeLeast"
              value={formData.likeLeast}
              onChange={handleChange}
              required
            />
          </label>

          <label>
            Do you have any suggestions for improvement?*
            <textarea
              name="suggestions"
              value={formData.suggestions}
              onChange={handleChange}
              required
            />
          </label>

          <label>
            Any additional comments or feedback?*
            <textarea
              name="additionalComments"
              value={formData.additionalComments}
              onChange={handleChange}
            />
          </label>

          <button type="submit">Submit Feedback</button>
          <br />
          <br />
          <small>Fields marked with an asterisk (*) are mandatory.</small>
        </form>
      </div>
    </Layout>
  );
};

export default SchoolTourFeedbackForm;
